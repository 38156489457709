<template>
  <v-workflow-card variant="flat">
    <v-card-title>Enter your vehicle information</v-card-title>
    <v-card-text>
      <v-form ref="vinForm">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" class="mt-2 mb-4">
            <v-text-field
              v-model="vinSearch"
              id="vin-input"
              label="VIN"
              maxlength="17"
              validate-on="blur"
              :rules="requiredVin"
              @keydown.space="preventSpace"
              @keydown.enter="keydownEnterNav($event, doVinSearch)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" sm="7" class="pb-0 pb-sm-3">
            <UiNavButton @click="doVinSearch" :loading="loading">
              Next
              <IconsArrowRight class="ml-1" />
            </UiNavButton>
          </v-col>
        </v-row>

        <v-dialog v-model="showDialog">
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="10" md="8" lg="6">
                <v-sheet class="pa-6 rounded-xl elevation-0" color="surfaceContainerHighLight">
                  <UiButtonSelect :items="vinSearchResults" @select="selectVehicleFromVinSearch">
                    <template #title>
                      <p class="text-subtitle-1 text-onSurfaceLight text-center font-weight-medium mb-4" :style="{ lineHeight: 1.5 }">
                        Multiple vehicles were found for that VIN. Please select your vehicle to continue.
                      </p>
                    </template>
                  </UiButtonSelect>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-dialog>
      </v-form>
    </v-card-text>
  </v-workflow-card>
</template>

<script setup>
const logger = useLogger("VinWorkflow");
const { handleVin, preventSpace, keydownEnterNav } = useInputControl();
const store = useAlertStore();
const vinForm = ref(null);
const loading = ref(false);
const vinSearch = ref("");
const showDialog = ref(false);

if (import.meta.env.VITE_USE_TEST_DATA === "true") {
  vinSearch.value = "1G4HD57M59U125977"; // for multiple vin results: 1GKDT13WXY2334166
  // vinSearch.value = "1GKDT13WXY2334166"; // for multiple vin results: 1GKDT13WXY2334166
}

// const rulesInject = shallowRef([]);

// watcher to format the vinSearch value as it is typed
watch(vinSearch, (newVal) => {
  vinSearch.value = handleVin(newVal);

  // handle the form display (show the vehicle select if multiple vehicles are found)
  if (showVehicleSelect.value) {
    resetVinSearch();
  }
});

// watcher to execute method on v-dialog close
watch(showDialog, (newVal) => {
  logger.debug("showDialog watcher newVal: ", newVal);
  if (!newVal) {
    resetVinSearch();
  }
});

const { requiredVin } = useValidation();

const props = defineProps({
  vehicleModel: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["select-vehicle"]);

const { vinSearchResults, findVehicleByVin, setVehicle } = props.vehicleModel;

const showVehicleSelect = computed(() => vinSearchResults.value.length > 0); // show/hide the vehicle select when multiple vehicles are found for VIN

// clear vinSearchResults on v-dialog close
const resetVinSearch = () => {
  loading.value = false;
  vinForm.value.resetValidation();

  if (showVehicleSelect.value) {
    logger.debug("resetVinSearch - clearing vinSearchResults");
    setTimeout(() => {
      vinSearchResults.value = []; // if v-dialog is open, allow it to close before clearing the vinSearchResults
    }, 500);
  }
};

function sendSelectedVehicle(vehicleDetails) {
  emit("select-vehicle", vehicleDetails);
}

const doVinSearch = async () => {
  // TODO: rulesInject is meant to display input validation message only after first submit
  // but it is not working as expected; apply fix to other forms with text fields
  // rulesInject.value = await requiredVin.value;

  try {
    loading.value = true;
    const { valid } = await vinForm.value.validate();
    if (!valid) {
      loading.value = false;
      return;
    }
    const result = await findVehicleByVin(vinSearch.value);
    if (!result) {
      loading.value = false;
      store.setAlert("No vehicle found");
      return;
    } else if (!Array.isArray(result)) {
      // loading.value = false; // loading is left true so the animation continues through the nav
      // we have a specific vehicle - go!
      sendSelectedVehicle(result);
    } else if (result.length > 1) {
      // we have multiple vehicles - show the vehicle select
      vinSearchResults.value = result;
      showDialog.value = true;
    }
  } catch (error) {
    loading.value = false;
    store.setAlert("Error searching for vehicle by vin. " + error.message);
  }
  // note:  no need for an else because
  // A) if one vehicle, it will be auto - loaded and
  // B) if multiple vehicles, we populate the vinSearchResults list and show that on a conditional
};

const selectVehicleFromVinSearch = async (vinVehicle) => {
  await setVehicle(vinVehicle); // we have all vehicle details - set the vehicle & go
  sendSelectedVehicle(vinVehicle); // send the selected vehicle to the parent and nav
};
</script>
